.editor-container {
  --scale: 1;
  --top: 1rem;
  --left: 1rem;
}

.editor-container.fullScreenEditor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.document-editor__editable-container {
  /*noinspection CssUnresolvedCustomProperty*/
  background: var(--ck-color-base-foreground);
  min-height: 100px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden !important;
}

.ck-editor__editable p {
  width: 100% !important;
  margin-right: 0 !important;
}

.main_editor > div {
  position: absolute;
  top: var(--top);
  left: var(--left);
  width: 1366px;
  height: 768px;
  z-index: 1;
  border: 2px hsl(0, 0%, 82.7%) solid !important;
  /*noinspection CssUnresolvedCustomProperty*/
  border-radius: var(--ck-border-radius) !important;
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);
  transform-origin: top left;
  transform: scale(var(--scale));
  background: white center;
  background-size: cover;
  overflow: hidden !important;
}

.ck .dotted-in-editor {
  min-width: 30px;
  min-height: 10px;
  border: 1px dotted;
}

div.dotted-in-editor {
  min-width: 80px;
  min-height: 80px;
}

/* ---------------------------------------
   Background
   --------------------------------------- */
.whiteLavaLampBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/white-mesh.webp');
}

.blueLavaLampBackgroundEditor > div {
  color: white;
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/blue-mesh.webp');
}

.starsBackgroundEditor > div {
  color: white;
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/stars.webp');
}

/* CodeCosmos */
.CodeCosmosBlueLavaLampBackgroundEditor > div {
  color: white;
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/CodeCosmos/blue-mesh.webp');
}
.CodeCosmosSideBlueLavaLampBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/CodeCosmos/side-blue-mesh.webp');
}
.CodeCosmosWhiteLavaLampBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/CodeCosmos/white-mesh.webp');
}

/* CodeFever */
.CodeFeverAnonymousBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/CodeFever/achtergrond-CF-anonymous.webp');
}
.CodeFeverEridersBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/CodeFever/achtergrond-CF-eriders.webp');
}
.CodeFeverMinecraftBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/CodeFever/achtergrond-CF-minecraft.webp');
}
.CodeFeverPythonBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/CodeFever/achtergrond-CF-python.webp');
}
.CodeFeverStreepkeBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/CodeFever/achtergrond-CF-streep.webp');
}

/* FTRPRF */
.FTRPRFBlueLavaLampBackgroundEditor > div {
  color: white;
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/FTRPRF/blue-mesh.webp');
}
.FTRPRFSideBlueLavaLampBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/FTRPRF/side-blue-mesh.webp');
}
.FTRPRFWhiteLavaLampBackgroundEditor > div {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/FTRPRF/white-mesh.webp');
}

/* thumbnails */
.whiteLavaLampBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/white-mesh.webp');
}

.blueLavaLampBackgroundThumbnail {
  color: white;
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/blue-mesh.webp');
}

.starsBackgroundThumbnail {
  color: white;
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/stars.webp');
}

/* CodeCosmos thumbnails */
.CodeCosmosBlueLavaLampBackgroundThumbnail {
  color: white;
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeCosmos/blue-mesh.webp');
}
.CodeCosmosSideBlueLavaLampBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeCosmos/side-blue-mesh.webp');
}
.CodeCosmosWhiteLavaLampBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeCosmos/white-mesh.webp');
}

/* CodeFever thumbnails */
.CodeFeverAnonymousBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-anonymous.jpg');
}
.CodeFeverEridersBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-eriders.jpg');
}
.CodeFeverMinecraftBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-minecraft.jpg');
}
.CodeFeverPythonBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-python.jpg');
}
.CodeFeverStreepkeBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/CodeFever/tn_achtergrond-CF-streep.jpg');
}


/* FTRPRF thumbnails */
.FTRPRFBlueLavaLampBackgroundThumbnail {
  color: white;
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/FTRPRF/blue-mesh.webp');
}
.FTRPRFSideBlueLavaLampBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/FTRPRF/side-blue-mesh.webp');
}
.FTRPRFWhiteLavaLampBackgroundThumbnail {
  background-image: url('https://codefeverpublic.blob.core.windows.net/backgrounds/thumbnails/FTRPRF/white-mesh.webp');
}

/* ---------------------------------------
   End Background
   --------------------------------------- */

.side_bar_editor .ck-editor__editable {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: white;
}

.ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck.ck-toolbar {
  border: 0 !important;
  /*noinspection CssUnresolvedCustomProperty*/
  border-bottom: 1px solid var(--ck-color-toolbar-border) !important;
}

.react-select-bg-container {
  width: 50% !important;
}
.react-select-bg__control {
  border: none !important;
  box-shadow: none !important;
  height: 5rem !important;
  width: auto;
}

.react-select-bg__value-container {
  padding-left: 0 !important;
  height: 100%;
  width: 100%;
}

.react-select-bg__single-value {
  height: 100%;
  width: 100%;
}

.react-select-bg__single-value * {
  filter: drop-shadow(0 0 #0000);
}

.react-select-bg__indicators {
  display: none !important;
}

/* -------------- fix for iframe ----------------- */
.ck .html-object-embed.ck-widget:has(.iframe, .ck-iframe) {
  padding: 0;
  background-color: transparent;
  border-color: transparent;
  color: transparent;
  display: block;
}

.ck-widget.html-object-embed::before {
  display: none;
  content: '';
}

.ck-editor__editable
  table:not([style*='solid'], [style*='groove'])
  .ck-editor__nested-editable,
.ck-editor__editable table:not([style*='solid;'], [style*='groove']) {
  border: 1px dashed !important;
}

.ck-editor__editable
  table
  .ck-editor__nested-editable:not(th, [style*='background-color']) {
  background-color: transparent !important;
}

.ck-table-form__border-width,
.ck-table-form__border-color,
.ck-table-form__border-style
  ul
  li:not(:nth-child(1), :nth-child(2), :nth-child(6)),
.ck-table-cell-properties-form .ck-table-form__border-row {
  display: none !important;
}

/* -------------- fix for table ----------------- */

.ck.ck-editor__editable_inline,
.main-editor > div.ck-content {
  overflow: scroll !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ck.ck-editor__editable_inline::-webkit-scrollbar,
.main-editor > div.ck-content::-webkit-scrollbar {
  display: none;
}

.ck5Slide table,
.ck-content .table,
figure.table,
figure.ck-widget {
  max-height: min(768px, 100%);
  max-width: min(1366px, 100%);
}

figure.ck-widget > table > tbody > td {
  width: unset !important;
}

.SlideViewerTextSlide ul {
  list-style: disc;
}

.SlideViewerTextSlide ul ul {
  list-style: circle;
}

.SlideViewerTextSlide ul ul ul {
  list-style: square;
}

.main_editor li {
  padding: 5px 0;
}

/** Fix for image **/
.ck-content .image-inline img {
  max-width: 100% !important;
}

.main_editor ul {
  @apply list-disc;
}

.main_editor ol {
  @apply list-decimal;
}

[data-test='slideviewer-sidebar'] + div .ck5Slide {
  left: 0 !important;
}

/* Text styles in dropdown */
button.ck5h2 {
  color: #0b172c !important;
  font-size: 3rem !important;
  font-weight: bold !important;
  letter-spacing: 2px !important;
  line-height: 1.25 !important;
  text-transform: uppercase !important;
}

button.ck5h3 {
  color: rgb(37, 99, 235) !important;
  font-size: 2rem !important;
  font-weight: 500 !important;
  line-height: 1.25 !important;
}

button.ck5p {
  color: rgb(0, 0, 0) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.ck5Slide .ck-content {
  padding: 3rem !important;
}

.teacher-sidebar .ck-content {
  padding: 1rem !important;
}

.ck-content > *:first-child {
  margin-top: 0 !important;
}

.ck-content > *:last-child {
  margin-bottom: 0 !important;
}

.sidebar_ck5 .ck-dropdown:has(> .ck-dropdown__panel-visible),
.sidebar_ck5 .ck-dropdown:has(> .ck-dropdown__button[aria-expanded='true']) {
  width: 100%;
}

.sidebar_ck5 .ck-dropdown__panel-visible {
  left: 0;
  overflow: auto;
}

.ck.ck-style-panel {
  overflow: auto;
}

.image.ck-widget.ck-widget_with-resizer.image_resized.ck-widget_selected {
  height: auto;
}

/** don't allow a modal in a modal **/
.modalSideBar [data-cke-tooltip-text='Modal'] {
  display: none !important;
}

.ck-powered-by-balloon {
  display: none !important;
}

.ck-style-grid p.title-with-background {
  max-width: 100%;
  position: relative;
}

.ck-style-grid p.title-with-background.title-with-background--question::after {
  content: '?';
  font-size: 50px;
  position: absolute;
  top: -35px;
  right: 0;
  transform: rotateZ(-30deg);
  opacity: 0.4;
  @apply text-blue-500;
}

.ck-style-grid p.title-with-background.title-with-background--statement::after {
  content: '!';
  font-size: 50px;
  position: absolute;
  top: -35px;
  right: 0;
  transform: rotateZ(-20deg);
  opacity: 0.4;
  @apply text-blue-500;
}

/** Ensure pre-tags in the editor main screen don't look massively weird **/

.ck-content pre[class*="language-"] {
  background: hsl(0, 0%, 8%);
}
.cke_top {
  border-bottom: 10px solid #ebecf0;
  border-radius: 4px;
  box-shadow: 0 2px 3px -3px rgba(220, 220, 220, 1);
  box-sizing: border-box;
}

.cke_combo_text {
  min-width: 75px;
}

a.cke_button {
  padding: 0.2em;
}

@font-face {
  font-family: 'Editor-Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/icons/editor-font.eot');
  /* IE9 Compat Modes */
  src: local('Editor Icons'), local('Editor-Icons'),
    url('./fonts/icons/editor-font.eot') format('embedded-opentype'),
    /* Super Modern Browsers */ url('./fonts/icons/editor-font.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/icons/editor-font.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/icons/editor-font.svg')
      format('svg');
  /* Legacy iOS */
}

.cke_button__bold_icon::before {
  content: '\e826';
}

.cke_button__italic_icon::before {
  content: '\e800';
}

.cke_button__underline_icon::before {
  content: '\e80a';
}

.cke_button__strike_icon::before {
  content: '\e83e';
}

.cke_button__subscript_icon::before {
  content: '\f12c';
  font-size: 1rem;
  position: relative;
  left: 0.3em;
}

.cke_button__superscript_icon::before {
  content: '\f12b';
  font-size: 1rem;
  position: relative;
  left: 0.3em;
}

.cke_button__copyformatting_icon::before {
  content: '\e801';
  font-size: 1rem;
  position: relative;
  left: 0.3em;
}

.cke_button__justifyleft_icon::before {
  content: '\e807';
}

.cke_button__justifycenter_icon::before {
  content: '\e809';
}

.cke_button__justifyright_icon::before {
  content: '\e808';
}

.cke_button__justifyblock_icon::before {
  content: '\e902';
}

.cke_button__numberedlist_icon::before {
  content: '\e828';
}

.cke_button__bulletedlist_icon::before {
  content: '\e833';
}

.cke_button__blockquote_icon::before {
  content: '\e82f';
}

.cke_button__outdent_icon::before {
  content: '\e83c';
}

.cke_button__indent_icon::before {
  content: '\e83b';
}

.cke_button__link_icon::before {
  content: '\e83f';
}

.cke_button__image_icon::before {
  content: '\e820';
}

.cke_button__imageCustom_icon::before {
  content: '\e820';
}

.cke_button__table_icon::before {
  content: '\e80b';
}

.cke_button__iframe_icon::before {
  content: '\e803';
  font-size: 1rem;
  position: relative;
  left: 0.3em;
}

.cke_button__find_icon::before {
  content: '\e810';
}

.cke_button__textcolor_icon::before {
  content: '\f020';
  font-size: 0.9rem;
  position: relative;
  left: 0.3em;
}

.cke_button__bgcolor_icon::before {
  content: '\e839';
}

.cke_button__maximize_icon::before {
  content: '\f0b2';
  position: relative;
  font-size: 1rem;
  left: 0.3em;
}

.cke_button__undo_icon::before {
  content: '\e82b';
}

.cke_button__redo_icon::before {
  content: '\e82e';
}

.cke_button__removeformat_icon::before {
  content: '\e901';
}

.cke_button__specialchar_icon::before {
  content: '\e900';
  font-size: 0.9rem;
  position: relative;
  left: 0.3em;
}
@media screen and (min-width: 1500px) {
  .top_right_toolgroup {
    position: absolute !important;
    top: 1em !important;
    right: 1em !important;
  }
}


.Editor {
  flex-grow: 1;
}
